import create from "zustand";
import { Venue } from "../pages/customers/_config/customerService.interface";

interface State {
  venuesList: Venue[];
  setVenuesList: (venuesList?: Venue[]) => void;
}

const useVenuesStore = create<State>((set) => ({
  venuesList: [],
  setVenuesList: (venuesList) => set({ venuesList }),
}));

export default useVenuesStore;
