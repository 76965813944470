import create from "zustand";
import data from "../_config/data";
import moment from "moment";
import { persist } from "zustand/middleware";
import axios from "axios";
import to from "await-to-js";
import { GridSelectionModel } from "@mui/x-data-grid";
import Vars from "../_config/vars";
import { Config } from "../../../config";
import { hasBeenContacted } from "../utils/bookingRow";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { LogChangeRow } from "../components/LogsTable/interfaces/LogChangeRow.interface";

type SnackbarState = {
  open: boolean;
  message: string;
  severity: "success" | "warning" | "error" | "info";
};

interface State {
  API_URL: string;
  API_PROD_URL: string;
  getConfig: () => {
    headers: {
      "Content-Type": string;
      Authorization: string;
    };
  };
  // DATA
  bookingsLocalStorage: any[];
  bookingsPendingsLocalStorage: any[];
  bookings: any[];
  setBookings: () => Promise<boolean>;
  bookingsPending: any[];
  setBookingsPending: () => Promise<boolean>;
  selectedBooking: any;
  setSelectedBooking: (bookingId: string) => Promise<boolean>;
  setSelectedBookingByRequest: (bookingId: string | null) => Promise<void>;
  getProviderLogs: (booking: any) => void;
  bookingHandlers: any[];
  setBookingHandlers: () => Promise<boolean>;
  bookingHandlerPending: any[];
  setBookingHandlerPending: () => Promise<boolean>;
  bookingRows: any[];
  setBookingRows: () => Promise<boolean>;
  bookingPendingRows: any[];
  setBookingPendingRows: () => Promise<boolean>;
  selectedBookingHandler: any;
  setSelectedBookingHandler: () => Promise<boolean>;
  refundData: any;
  setRefundData: (refundData: any) => void;
  createLog: (log: any) => Promise<void>;
  snackBar: {
    open: boolean;
    message: string;
    severity: "success" | "error" | "info" | "warning" | undefined;
  };
  setSnackBar: (snackBar: any) => void;
  countBookings: number;
  setCountBookings: (tab: string) => Promise<boolean>;
  purchaseProvider: any;
  setPurchaseProvider: (purchaseProvider: any) => void;
  fileManagement: any;
  // FILTERS
  dateRangeFilter: {
    startDate: Date;
    endDate: Date;
  };
  setDateRangeFilter: (dateRange: any) => void;
  pagination: {
    pendings: {
      page: number;
      take: number;
    };
    bookings: {
      page: number;
      take: number;
    };
  };
  setPagination: (pagination: any) => void;
  filterStatus: any;
  setFilterStatus: (filterStatus: any) => void;
  selectedVenues: any[];
  setSelectedVenues: (selectedVenues: any) => void;
  selectedVenuesGetBookings: string[];
  setSelectedVenuesGetBookings: (selectedVenuesGetBookings: string[]) => void;
  venues: any[];
  setVenues: () => Promise<void>;
  selectedBookings: any;
  setSelectedBookings: (param: GridSelectionModel) => void;
  columnsTableFilter: {
    booking: {};
    pending: {};
  };
  setColumnsTableFilter: (columnsTableFilter: any) => void;
  // NEW DATA
  newBooking: any;
  setNewBooking: (newBooking: any) => void;
  newManualPurchase: any;
  setNewManualPurchase: (newManualPurchase: any) => void;
  newRefund: any;
  setNewRefund: (newRefund: any) => void;
  newCustomer: any;
  setNewCustomer: (newCustomer: any) => void;
  inputSearch: string;
  setInputSearch: (inputValue: string) => void;
  uploadFiles: any;
  setUploadFiles: (uploadFiles: any) => void;
  fileTypeToUpload: string;
  setFileTypeToUpload: (fileTypeToUpload: string) => void;
  idBookingToUploadFile: string;
  setIdBookingToUploadFile: (idBookingToUploadFile: string) => void;
  // OLD DATA
  oldBooking: any;
  setOldBooking: (oldBooking: any) => void;
  oldCustomer: any;
  setOldCustomer: (oldCustomer: any) => void;
  oldManualPurchase: any;
  setOldManualPurchase: (oldManualPurchase: any) => void;
  sendToOutsourcing: (providerId: string, status: string) => Promise<boolean>;
  // TOGGLES
  editBookingForm: boolean;
  setEditBookingForm: (editBookingForm: boolean) => void;
  // ACTIONS
  updateBooking: () => Promise<boolean>;
  updateOptions: (purchaseProviders: any) => Promise<boolean>;
  manualPurchase: () => Promise<boolean>;
  refundBooking: () => Promise<boolean>;
  unblockBooking: () => Promise<boolean>;
  sendTickets: (actionFrom?: string) => Promise<boolean>;
  retryTickets: () => Promise<boolean>;
  updateCustomer: (custumerId: string) => Promise<boolean>;
  updateTrackingNumber: (newTrackingNumber: string) => Promise<boolean>;
  updateCard: () => Promise<boolean>;
  resendToBot: () => Promise<boolean>;
  getPurchaseProvider: () => Promise<boolean>;
  getPurchasesProvidersByBookingId: (bookingId: string) => Promise<boolean>;
  updatePurchaseEmail: (email?: string) => Promise<boolean>;
  updateStatusPurchaseProviderSuccess: () => Promise<boolean>;
  updatePaymentId: () => Promise<boolean>;
  updateStatusBooking: () => Promise<boolean>;
  updateStatusPurchaseProvider: (status: string) => Promise<boolean>;
  updatePurchaseEmailByCS: () => Promise<boolean>;
  updateTimeRange: () => Promise<boolean>;
  updateVisitDate: () => Promise<boolean>;
  getFileManagement: (purchaseProviderId: string) => Promise<any>;
  downloadTickets: (bookingIds?: string[]) => Promise<boolean>;
  downloadBill: (bookingIds?: string[]) => Promise<boolean>;
  getBookingHandler: (bookingHandlerId: string) => Promise<any>;
  downloadCSV: () => Promise<boolean>;
  searchBooking: (actionFrom: string) => Promise<boolean>;
  uploadFile: () => Promise<boolean>;
  convertToBase64: (file: any) => Promise<string>;
  sendEmailIndisponibility: (pruchases_providers_id: string[]) => Promise<{
    isDone: boolean;
    msg: string;
  }>;
  getFileManagementNew: (purchaseProviderId: string | string[]) => Promise<any>;
  retryFiles: (purchase_provider_id: string) => Promise<boolean>;
  // OPEN MODALS
  openEditDialog: boolean;
  setOpenEditDialog: (openEditDialog: boolean) => void;
  openManualPurchaseDialog: boolean;
  setOpenManualPurchaseDialog: (openManualPurchaseDialog: boolean) => void;
  openRefundDialog: boolean;
  setOpenRefundDialog: (openRefundDialog: boolean) => void;
  openUnblockDialog: boolean;
  setOpenUnblockDialog: (openUnblockDialog: boolean) => void;
  openEditCustomerDialog: boolean;
  setOpenEditCustomerDialog: (openEditCustomerDialog: boolean) => void;
  openUploadFileDialog: boolean;
  setOpenUploadFileDialog: (openUploadFileDialog: boolean) => void;
  openRevisedDialog: boolean;
  setOpenRevisedDialog: (openRevisedDialog: boolean) => void;
  openRemoveFileDialog: boolean;
  setOpenRemoveFileDialog: (openRemoveFileDialog: boolean) => void;
  // STATUS REPORT
  statusReport: { label: string; value: number; color: string }[];
  loadStatusReport: (startDate: Date, endDate: Date) => Promise<void>;
  // FILTER
  filterCreatedAt: boolean;
  setFilterCreatedAt: (filterCreatedAt: boolean) => void;
  // Comments
  comment: string;
  setComment: (comment: string) => void;
  idBookingToContact: string;
  setIdBookingToContact: (idBookingToContact: string) => void;
}

const useBookingStore = create<State>()(
  persist(
    (set, get) => {
      return {
        API_URL: "http://localhost:5432",
        API_PROD_URL: Config.REACT_APP_API_TICKETDOOR,
        getConfig() {
          return {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("ticketdoorToken")}`,
            },
          };
        },
        // DATA
        bookingsLocalStorage: [],
        bookingsPendingsLocalStorage: [],
        bookings: [],
        setBookings: async () => {
          const {
            pagination,
            dateRangeFilter,
            selectedVenues,
            getConfig,
            setCountBookings,
          } = get();
          const start_date = moment(dateRangeFilter.startDate)
            .startOf("day")
            .toISOString();
          const end_date = moment(dateRangeFilter.endDate)
            .endOf("day")
            .toISOString();

          let query_venue = "";
          selectedVenues.map((venue) => {
            query_venue += `&venue_id=${venue}`;
          });

          if (query_venue === "") return false;

          const query = `?start_date=${start_date}&end_date=${end_date}&page=${pagination.bookings.page}&status_booking=Processing&status_booking_handler=Success`;
          const url = `${
            get().API_PROD_URL
          }/bookings/find/filter-purchasesproviders-notifications${query}${query_venue}`;
          //console.log("url setBookings", url);
          const [err, response] = await to(axios.get(url, getConfig()));
          if (err) return false;
          //console.log("response bookings", response.data);
          set({ bookings: response.data, bookingsLocalStorage: response.data });
          setCountBookings("bookings");

          get().loadStatusReport(
            dateRangeFilter.startDate,
            dateRangeFilter.endDate
          );
          return true;
        },
        bookingsPending: [],
        setBookingsPending: async () => {
          const {
            pagination,
            filterStatus,
            selectedVenues,
            selectedBooking,
            getConfig,
            setCountBookings,
          } = get();
          let query_status_booking = "";
          if (filterStatus.errorPurchasesProviders === true)
            query_status_booking += "&status_purchases_providers=Error";
          if (filterStatus.blockCard === true)
            query_status_booking += "&purchases_providers_tag=Block card";
          if (filterStatus.unavailableTime === true)
            query_status_booking += "&purchases_providers_tag=Unavailable time";
          if (filterStatus.errorSelector === true)
            query_status_booking += "&purchases_providers_tag=Error selector";
          if (filterStatus.errorNotifications === true)
            query_status_booking += "&status_notifications=Error";
          if (filterStatus.bounce === true)
            query_status_booking += "&notifications_tag=Bounce";
          if (filterStatus.otherError === true)
            query_status_booking += "&notifications_tag=Other error";
          if (filterStatus.errorPayment === true)
            query_status_booking += "&status_payment=Error";
          let query_venue = "";
          selectedVenues.map((venue) => {
            query_venue += `&venue_id=${venue}`;
          });

          if (query_venue === "") {
            console.log("hola");
            set({ bookingsPending: [] });
            setCountBookings("pendings");
            return false;
          }

          const start_date = "2023-11-06T22:00:00.000Z";
          const end_date = moment(new Date()).endOf("day").toISOString();
          let query = `?start_date=${start_date}&end_date=${end_date}&status_booking=Processing&status_booking_handler=Error&status_booking_handler=Processing`;
          //let query = `?start_date=${start_date}&end_date=${end_date}&status_booking=Processing&status_purchases_providers=Error&status_purchases_providers=Processing&status_payment=Error&status_payment=Created&status_payment=Authorized&status_payment=Success`;
          if (query_status_booking !== "")
            query = `?start_date=${start_date}&end_date=${end_date}&status_booking=Processing`;

          var url = get().API_PROD_URL;
          url += `/bookings/find/filter-purchasesproviders-notifications${query}${query_status_booking}${query_venue}&page=${pagination.pendings.page}`;

          //console.log("url setBookingsPending", url);
          if (filterStatus.allErrors === true) {
            const queryDate = `start_date=${start_date}&end_date=${end_date}`;
            const queryPage = `page=${pagination.pendings.page}`;

            var baseUrl = get().API_PROD_URL;
            console.log("filter created at 2");
            baseUrl +=
              `/bookings/find/filter-purchasesproviders-notifications?status_booking=Processing&` +
              `${queryDate}&${queryPage}`;

            const multiQuery = [
              "status_purchases_providers=Error",
              "status_notifications=Error",
              "status_payment=Error",
            ];
            const data = await Promise.all(
              multiQuery.map(async (query) => {
                const response = await axios.get(
                  `${baseUrl}&${query}`,
                  getConfig()
                );
                return response.data;
              })
            );
            console.log("data all", data.flat(1));
            set({ bookingsPending: data.flat(1) });
            setCountBookings("pendings");
            return true;
          }
          //console.log("url", url);

          if (selectedVenues.length > 0) {
            const [err, response] = await to(axios.get(url, getConfig()));
            if (err) return false;
            //console.log("response pendings", response.data);
            set({
              bookingsPending: response.data,
              bookingsPendingsLocalStorage: response.data,
            });

            if (selectedBooking) {
              const booking = response.data.find(
                (booking: any) => booking.id === selectedBooking.id
              );
              if (booking) {
                set({ selectedBooking: booking });
              }
            }
          }

          return true;
        },
        filterCreatedAt: true,
        setFilterCreatedAt: (filterCreatedAt) => {
          //const filterStatus = localStorage.getItem("filterCreatedAt");
          set({ filterCreatedAt });
        },
        bookingHandlers: [],
        setBookingHandlers: async () => {
          set({ bookingHandlers: data });
          return true;
        },
        bookingHandlerPending: [],
        setBookingHandlerPending: async () => {
          set({ bookingHandlerPending: [] });
          return true;
        },
        bookingRows: [],
        setBookingRows: async () => {
          const { bookings, getFileManagement, getFileManagementNew } = get();
          const bookingRows = await Promise.all(
            bookings.map(async (booking) => {
              const { customer, booking_handler, cart } = booking;
              const { payment } = booking;
              const { purchases_providers } = booking_handler ?? {
                purchases_providers: [],
              };

              const { tracking_number } = purchases_providers[0] ?? {
                tracking_number: "",
              };
              const { items } = cart;
              const { options, activity } = items[0];

              let visit_date = "";
              let time_range = "";
              var entryDateObject = options.find(
                (option: any) => option.name === "EntryDate"
              );
              if (entryDateObject) {
                visit_date = entryDateObject.value;
              }
              var entryTimeObject = options.find(
                (option: any) => option.name === "EntryTime"
              );
              if (entryTimeObject) {
                time_range = entryTimeObject.value;
              }

              let statusBooking = "";
              let statusBookingPayment = "";
              if (payment.status === "Refund") statusBookingPayment = "Refund";
              if (payment.status === "RefundPartial")
                statusBookingPayment = "Refund Partial";
              if (payment.status === "Chargeback")
                statusBookingPayment = "Chargeback";

              if (booking.status === "Processing") {
                if (booking_handler.status === "Success") {
                  statusBooking = "Success";
                } else {
                  statusBooking = "Error";
                }
              } else {
                statusBooking = booking.status;
              }

              if (
                statusBookingPayment === "Refund" ||
                statusBookingPayment === "Chargeback" ||
                statusBookingPayment === "Refund Partial"
              ) {
                statusBooking = statusBookingPayment;
              }

              var createdAt = "";
              if (purchases_providers.length !== 0) {
                createdAt = moment(purchases_providers[0].created_at).format(
                  "DD/MM/YYYY HH:mm:ss"
                );
              } else {
                createdAt = "-";
              }

              return {
                id: booking.id,
                createdAt,
                name: customer.first_name + " " + customer.last_name,
                email: customer.email,
                trackingNumber: tracking_number,
                visitDate: visit_date ? visit_date : "-",
                timeRange: time_range ? time_range : "-",
                categoryTicket: activity.name,
                status: statusBooking,
                tickets: false,
                bill: false,
                purchaseProviderId: purchases_providers[0].id,
              };
            })
          );

          const purchaseProviderIds = bookings.map(
            (booking) => booking.booking_handler.purchases_providers[0].id
          );

          const filesManagement =
            await getFileManagementNew(purchaseProviderIds);
          if (!filesManagement) return false;
          bookingRows.map((bookingRow) => {
            const fileManagement = filesManagement.find(
              (fileManagement: any) =>
                fileManagement.file_name === bookingRow.purchaseProviderId
            );
            if (fileManagement) {
              bookingRow.tickets = fileManagement.has_tickets;
              bookingRow.bill = fileManagement.has_bill;
            }
          });

          set({ bookingRows });

          return true;
        },
        bookingPendingRows: [],
        setBookingPendingRows: async () => {
          const { bookingsPending, getFileManagementNew } = get();
          //console.log("bookingsPending", bookingsPending);
          const bookingPendingRows = await Promise.all(
            bookingsPending.map(async (booking) => {
              const { booking_handler, customer, cart } = booking;
              const { purchases_providers } = booking_handler
                ? booking_handler
                : null;
              const { items } = cart;
              const { options } = items[0];
              let created_at = moment(booking.created_at).format(
                "DD/MM/YYYY HH:mm:ss"
              );

              let tracking_number = purchases_providers[0].tracking_number;
              let statusBooking = "Processing";
              let visit_date = "";
              let time_range = "";
              var entryDateObject = options.find(
                (option: any) => option.name === "EntryDate"
              );
              if (entryDateObject) {
                visit_date = entryDateObject.value;
              }
              var entryTimeObject = options.find(
                (option: any) => option.name === "EntryTime"
              );
              if (entryTimeObject) {
                time_range = entryTimeObject.value;
              }
              let categoryTicket = cart.items[0].activity.name;
              let purchase_provider_status = purchases_providers[0].status;
              let notificationsStatus = "Processing";

              let notifications_purchase_provider =
                purchases_providers[0].notifications;
              let notifications_payment = booking.payment.notifications;

              if (
                notifications_payment.length > 0 ||
                notifications_purchase_provider.length > 0
              ) {
                const notifications = [
                  ...notifications_purchase_provider,
                  ...notifications_payment,
                ];

                const messageStatusMap: {
                  [key: string]: {
                    status: string[];
                    communicationTypes: string[];
                  };
                } = {};

                notifications.forEach((notification) => {
                  if (!messageStatusMap[notification.message_id]) {
                    messageStatusMap[notification.message_id] = {
                      status: [],
                      communicationTypes: [],
                    };
                  }
                  messageStatusMap[notification.message_id].status.push(
                    notification.status
                  );
                  messageStatusMap[
                    notification.message_id
                  ].communicationTypes.push(notification.communication_type);
                });

                const filteredMessageStatusMap = Object.keys(
                  messageStatusMap
                ).reduce(
                  (acc, key) => {
                    const { communicationTypes, status } =
                      messageStatusMap[key];
                    const filteredCommunicationTypes =
                      communicationTypes.filter(
                        (communicationType, index, self) => {
                          return self.indexOf(communicationType) === index;
                        }
                      );
                    acc[key] = {
                      status,
                      communicationTypes: filteredCommunicationTypes,
                    };
                    return acc;
                  },
                  {} as {
                    [key: string]: {
                      status: string[];
                      communicationTypes: string[];
                    };
                  }
                );

                //console.log("filteredMessageStatusMap", filteredMessageStatusMap);

                let statusConfirmEmail = false;
                let statusResolutions = false;
                Object.keys(filteredMessageStatusMap).forEach((key) => {
                  const { status, communicationTypes } =
                    filteredMessageStatusMap[key];

                  if (
                    communicationTypes.includes("email_confirmation") &&
                    status.includes("Success")
                  )
                    statusConfirmEmail = true;
                  if (
                    communicationTypes.includes("email_resolution") &&
                    status.includes("Success")
                  )
                    statusResolutions = true;

                  if (statusConfirmEmail && statusResolutions)
                    notificationsStatus = "Success";

                  if (
                    communicationTypes.includes("email_confirmation") &&
                    status.length < 2
                  )
                    notificationsStatus = "Error";
                  if (
                    communicationTypes.includes("email_resolution") &&
                    status.length < 2
                  )
                    notificationsStatus = "Error";
                });
              } else {
                notificationsStatus = "Error";
              }

              if (notifications_payment.length === 0)
                notificationsStatus = "Error";
              if (notifications_purchase_provider.length === 0)
                notificationsStatus = "Error";

              let statusBookingHandler = booking_handler.status;

              let tickets = null;
              let bill = null;
              let statusPurchaseProvider = purchases_providers[0].status;
              let hasQontoId = purchases_providers[0].payment_provider
                .payment_id
                ? "Success"
                : "Error";
              if (
                statusPurchaseProvider === "Error" ||
                notificationsStatus === "Error" ||
                // fileManagementStatus === "Error" ||
                statusBookingHandler === "Error" ||
                hasQontoId === "Error"
              ) {
                statusBooking = "Error";
              } else {
                statusBooking = "Processing";
              }

              return {
                id: booking.id,
                bookingStatus: statusBooking ? statusBooking : "-",
                errorTag: purchases_providers[0].error_tag
                  ? purchases_providers[0].error_tag
                  : "-",
                createdAt: created_at ? created_at : "-",
                name: customer.first_name
                  ? customer.first_name + " " + customer.last_name
                  : "-",
                email: customer.email ? customer.email : "-",
                trackingNumber: tracking_number ? tracking_number : "-",
                totalAmount: cart.total_price ? cart.total_price : "-",
                visitDate: visit_date ? visit_date : "-",
                timeRange: time_range ? time_range : "-",
                categoryTicket: categoryTicket ? categoryTicket : "-",
                statusPurchaseProvider: purchase_provider_status
                  ? purchase_provider_status
                  : "-",
                statusNotification: notificationsStatus
                  ? notificationsStatus
                  : "-",
                statusQontoId: purchases_providers[0].payment_provider
                  .payment_id
                  ? "Success"
                  : "Error",
                contact: hasBeenContacted(purchases_providers[0].logs),
                purchaseProviderId: purchases_providers[0].id,
                tickets: false,
                bill: false,
              };
            })
          );

          const purchaseProviderIds = bookingsPending.map(
            (booking) => booking.booking_handler.purchases_providers[0].id
          );

          const filesManagement =
            await getFileManagementNew(purchaseProviderIds);
          if (!filesManagement) return false;
          bookingPendingRows.map((bookingPendingRow) => {
            const fileManagement = filesManagement.find(
              (fileManagement: any) =>
                fileManagement.file_name ===
                bookingPendingRow.purchaseProviderId
            );
            if (fileManagement) {
              bookingPendingRow.tickets = fileManagement.has_tickets;
              bookingPendingRow.bill = fileManagement.has_bill;

              // if no has tickets
              if (!fileManagement.has_tickets || !fileManagement.has_bill) {
                bookingPendingRow.bookingStatus = "Error";
              }
            }
          });

          set({ bookingPendingRows });
          return true;
        },
        getFileManagementNew: async (purchaseProviderId) => {
          if (purchaseProviderId.length === 0) return;
          const { getConfig } = get();
          // ?id=1&id=2&id=3
          const query = Array.isArray(purchaseProviderId)
            ? purchaseProviderId.map((id) => `id=${id}`).join("&")
            : `id=${purchaseProviderId}`;
          const url = `${get().API_PROD_URL}/file-management/by-purchase-provider?${query}`;
          //console.log("url", url);
          const [err, response] = await to(axios.get(url, getConfig()));
          if (err) return false;
          //console.log("response", response.data);
          return response.data;
        },
        selectedBookingHandler: {},
        setSelectedBookingHandler: async () => {
          const { selectedBooking } = get();
          const bookingHandler = selectedBooking.booking_handler;
          if (!bookingHandler) return false;

          set({ selectedBookingHandler: bookingHandler });

          return true;
        },
        selectedBooking: {},
        setSelectedBooking: async (bookingId: string) => {
          const { bookingsLocalStorage, bookingsPendingsLocalStorage } = get();
          console.log("LOGS:");
          console.log(bookingsLocalStorage);
          console.log(bookingsPendingsLocalStorage);
          //console.log("bookings", bookingsLocalStorage);
          //console.log("bookingsPending", bookingsPendingsLocalStorage);

          let booking = bookingsLocalStorage.find(
            (booking) => booking.id === bookingId
          );
          if (booking === undefined) {
            booking = bookingsPendingsLocalStorage.find(
              (booking) => booking.id === bookingId
            );
          }

          if (!booking) {
            const url = `${get().API_PROD_URL}/bookings/${bookingId}`;
            const [err, response] = await to(axios.get(url, get().getConfig()));
            if (err) return false;
            booking = response.data;
          }

          if (!booking) return false;

          set({ selectedBooking: booking });

          return true;
        },
        setSelectedBookingByRequest: async (bookingId: string | null) => {
          try {
            //console.log("setSelectedBookingByRequest");
            console.log("bookingId: ", bookingId);
            if (!bookingId) {
              set({ selectedBooking: {} });
              return;
            }
            const url = `${get().API_PROD_URL}/bookings/${bookingId}`;
            const [err, response] = await to(axios.get(url, get().getConfig()));
            if (err) return;
            const booking = response.data;
            set({
              selectedBooking: booking,
            });
            get().getProviderLogs(booking);
          } catch (error) {
            console.log("error: ", error);
            throw error;
          }
        },
        getProviderLogs: async (booking: any) => {
          console.log(
            "purchase_provider_id: " +
              booking.booking_handler.purchases_providers[0].id
          );
          try {
            const url = `${get().API_PROD_URL}/purchase-provider/${
              booking.booking_handler.purchases_providers[0].id
            }`;
            const [err, response] = await to(axios.get(url, get().getConfig()));
            if (err) return false;
            set({ purchaseProvider: response.data });
          } catch (error) {
            console.log("error: ", error);
            throw error;
          }
        },
        createLog: async (log: any) => {
          const { getConfig, API_PROD_URL } = get();
          const url = `${API_PROD_URL}/log`;
          const [err, response] = await to(axios.post(url, log, getConfig()));
          if (err) return false;
          return response.data;
        },
        snackBar: {
          open: false,
          message: "",
          severity: undefined,
        },
        setSnackBar: (snackBar: any) => set({ snackBar }),
        countBookings: 0,
        setCountBookings: async (tab: string) => {
          const {
            dateRangeFilter,
            pagination,
            filterStatus,
            selectedVenues,
            getConfig,
          } = get();

          //console.log("tab", tab);

          let query_status_booking = "";
          if (filterStatus.errorPurchasesProviders === true)
            query_status_booking += "&status_purchases_providers=Error";
          if (filterStatus.blockCard === true)
            query_status_booking += "&purchases_providers_tag=Block card";
          if (filterStatus.unavailableTime === true)
            query_status_booking += "&purchases_providers_tag=Unavailable time";
          if (filterStatus.errorSelector === true)
            query_status_booking += "&purchases_providers_tag=Error selector";
          if (filterStatus.errorNotifications === true)
            query_status_booking += "&status_notifications=Error";
          if (filterStatus.bounce === true)
            query_status_booking += "&notifications_tag=Bounce";
          if (filterStatus.otherError === true)
            query_status_booking += "&notifications_tag=Other error";
          if (filterStatus.errorPayment === true)
            query_status_booking += "&status_payment=Error";

          let query = `?status_booking=Processing&status_booking_handler=Error&status_booking_handler=Processing`;
          if (query_status_booking !== "") query = `?status_booking=Processing`;
          if (tab === "bookings") {
            let query_date = "";
            if (dateRangeFilter.startDate) {
              query_date += `&start_date=${moment(dateRangeFilter.startDate)
                .startOf("day")
                .toISOString()}`;
            }
            if (dateRangeFilter.endDate) {
              query_date += `&end_date=${moment(dateRangeFilter.endDate)
                .endOf("day")
                .toISOString()}`;
            }
            query = `?status_booking=Processing&status_booking_handler=Success`;
            query += `${query_date}`;
          } else {
            const start_date = "2023-11-06T00:00:00.000Z";
            const end_date = moment(new Date()).endOf("day").toISOString();
            const query_date_range = `&start_date=${start_date}&end_date=${end_date}`;
            query += `${query_date_range}`;
          }

          let query_venue = "";
          selectedVenues.map((venue) => {
            query_venue += `&venue_id=${venue}`;
          });
          const url = `${
            get().API_PROD_URL
          }/bookings/find/count${query}${query_status_booking}${query_venue}`;
          //console.log("url counting", url);

          if (selectedVenues.length > 0) {
            const [err, response] = await to(axios.get(url, getConfig()));
            if (err) return false;
            const countBookings = response.data;
            //console.log("countBookings", countBookings);
            set({ countBookings });
          }

          return true;
        },
        // FILTERS
        dateRangeFilter: {
          startDate: new Date(),
          endDate: new Date(),
        },
        setDateRangeFilter: (dateRange: any) => {
          set(() => ({ dateRangeFilter: dateRange }));
        },
        pagination: {
          pendings: {
            page: 1,
            take: 10,
          },
          bookings: {
            page: 1,
            take: 10,
          },
        },
        setPagination: (pagination: any) => set({ pagination }),
        filterStatus: {
          allErrors: false,
          errorPurchasesProvider: false,
          errorNotifications: false,
          blockCard: false,
          unavailableTime: false,
          errorSelector: false,
          bounce: false,
          otherError: false,
          errorPayment: false,
        },
        setFilterStatus: (filterStatus: any) => {
          set({ filterStatus });
        },
        selectedVenues: [],
        setSelectedVenues: (selectedVenues: any) => set({ selectedVenues }),
        selectedVenuesGetBookings: [],
        setSelectedVenuesGetBookings: (selectedVenuesGetBookings: string[]) =>
          set({ selectedVenuesGetBookings }),
        venues: [],
        setVenues: async () => {
          const [err, response] = await to(
            axios.get(`${get().API_PROD_URL}/venues`, get().getConfig())
          );
          if (err) return console.log(err);
          const { data } = response;
          set({ venues: data });
        },
        selectedBookings: [],
        setSelectedBookings: (param: GridSelectionModel) => {
          set({ selectedBookings: param });
        },
        columnsTableFilter: {
          booking: {
            __check__: true,
            actions: true,
            createdAt: true,
            name: true,
            email: true,
            trackingNumber: true,
            visitDate: true,
            timeRange: true,
            categoryTicket: true,
            status: true,
            tickets: true,
            bill: true,
          },
          pending: {
            __check__: true,
            actions: true,
            createdAt: true,
            name: false,
            email: true,
            trackingNumber: true,
            visitDate: true,
            timeRange: true,
            categoryTicket: true,
            status: true,
            tickets: true,
            bill: true,
          },
        },
        setColumnsTableFilter: (columnsTableFilter: any) => {
          set({ columnsTableFilter });
        },

        // NEW DATA
        newBooking: {},
        setNewBooking: (newBooking: any) => set({ newBooking }),
        refundData: {},
        setRefundData: (refundData: any) => set({ refundData }),
        newManualPurchase: {},
        setNewManualPurchase: (newManualPurchase: any) =>
          set({ newManualPurchase }),
        newRefund: {},
        setNewRefund: (newRefund: any) => set({ newRefund }),
        newCustomer: {},
        setNewCustomer: (newCustomer: any) => set({ newCustomer }),
        inputSearch: "",
        setInputSearch: (inputValue: string) =>
          set({ inputSearch: inputValue }),
        uploadFiles: [],
        setUploadFiles: (uploadFiles: any) => set({ uploadFiles }),
        fileTypeToUpload: "",
        setFileTypeToUpload: (fileTypeToUpload: string) =>
          set({ fileTypeToUpload }),
        idBookingToUploadFile: "",
        setIdBookingToUploadFile: (idBookingToUploadFile: string) =>
          set({ idBookingToUploadFile }),
        // OLD DATA
        oldBooking: {},
        setOldBooking: (oldBooking: any) => set({ oldBooking }),
        oldCustomer: {},
        setOldCustomer: (oldCustomer: any) => set({ oldCustomer }),
        // TOGGLES
        editBookingForm: false,
        setEditBookingForm: (editBookingForm: boolean) =>
          set({ editBookingForm }),
        oldManualPurchase: {},
        setOldManualPurchase: (oldManualPurchase: any) =>
          set({ oldManualPurchase }),
        // ACTIONS
        sendToOutsourcing: async (providerId: string, status: string) => {
          const { API_PROD_URL, getConfig, createLog } = get();
          const url = `${API_PROD_URL}/purchase-provider/${providerId}`;
          const body = { is_manual_purchase: true };
          const [err] = await to(axios.patch(url, body, getConfig()));
          if (err) return false;
          const currentUser = useCurrentUser.getState().user;
          const by = currentUser
            ? `${currentUser.username} <${currentUser.email}>`
            : "CS";
          const log = {
            message: "Sent to Outsourcing",
            by,
            type: "Purchase",
            status,
            purchaseProviderId: providerId,
          };
          await createLog(log);
          return true;
        },
        updateBooking: async () => {
          const {
            updateStatusBooking,
            newBooking,
            oldBooking,
            updatePaymentId,
            updatePurchaseEmailByCS,
            updateOptions,
            updateTrackingNumber,
            createLog,
          } = get();

          console.log("update booking");
          console.log("newBooking", newBooking);
          console.log("oldBooking", oldBooking);

          let arrChanges: LogChangeRow[] = [];
          const purchaseProviders =
            get().selectedBooking.booking_handler.purchases_providers;

          var doneUpdateStatusBooking = true;
          if (newBooking.status !== oldBooking.status) {
            arrChanges.push({
              field: "Status",
              old: oldBooking.status,
              new: newBooking.status,
            });
            doneUpdateStatusBooking = await updateStatusBooking();
          }

          var doneUpdateTrackingNumber = true;
          if (newBooking.trackingNumber !== oldBooking.trackingNumber) {
            arrChanges.push({
              field: "Tracking Number",
              old: oldBooking.trackingNumber,
              new: newBooking.trackingNumber,
            });
            doneUpdateTrackingNumber = await updateTrackingNumber(
              newBooking.trackingNumber
            );
          }

          var doneUpdateOptions = true;
          if (
            newBooking.timeRange !== oldBooking.timeRange ||
            newBooking.visitDate !== oldBooking.visitDate
          )
            doneUpdateOptions = await updateOptions(purchaseProviders[0]);

          var doneUpdatePaymentId = true;
          if (newBooking.paymentId !== oldBooking.paymentId) {
            arrChanges.push({
              field: "Payment Id",
              old: oldBooking.paymentId,
              new: newBooking.paymentId,
            });
            doneUpdatePaymentId = await updatePaymentId();
          }

          var doneUpdatePurchaseEmail = true;
          if (newBooking.purchaseEmail !== oldBooking.purchaseEmail) {
            arrChanges.push({
              field: "Purchase Email",
              old: oldBooking.purchaseEmail,
              new: newBooking.purchaseEmail,
            });
            doneUpdatePurchaseEmail = await updatePurchaseEmailByCS();
          }

          if (
            !doneUpdateStatusBooking ||
            !doneUpdateOptions ||
            !doneUpdatePaymentId ||
            !doneUpdatePurchaseEmail ||
            !doneUpdateTrackingNumber
          )
            return false;
          if (arrChanges.length > 0) {
            const currentUser = useCurrentUser.getState().user;
            const by = currentUser
              ? `${currentUser.username} <${currentUser.email}>`
              : "CS";
            const log = {
              message: "Booking updated>>" + JSON.stringify(arrChanges),
              by,
              type: "Purchase",
              status: purchaseProviders[0].status,
              purchaseProviderId: purchaseProviders[0].id as string,
            };
            await createLog(log);
          }
          return true;
        },
        updateOptions: async (purchaseProvider: any) => {
          const {
            newBooking,
            selectedBooking,
            API_PROD_URL,
            getConfig,
            createLog,
          } = get();
          let arrChanges: { field: string; old: string; new: string }[] = [];
          const items = selectedBooking.cart.items;
          const providerItemsIds = purchaseProvider.itemIds;
          const itemsToChange = items.filter((item: any) =>
            providerItemsIds.includes(item.id)
          );

          const updateItems = itemsToChange.map((item: any) => {
            return {
              ...item,
              options: item.options.map((option: any) => {
                if (
                  option.name === "EntryDate" &&
                  option.value !== newBooking.visitDate
                ) {
                  arrChanges.push({
                    field: "EntryDate",
                    old: option.value,
                    new: newBooking.visitDate,
                  });
                  return {
                    ...option,
                    id: option.id ? option.id : option.option_id,
                    value: newBooking.visitDate
                      ? newBooking.visitDate
                      : option.value,
                  };
                }
                if (
                  option.name === "EntryTime" &&
                  option.value !== newBooking.timeRange
                ) {
                  arrChanges.push({
                    field: "EntryTime",
                    old: option.value,
                    new: newBooking.timeRange,
                  });
                  return {
                    ...option,
                    id: option.id ? option.id : option.option_id,
                    value: newBooking.timeRange
                      ? newBooking.timeRange
                      : option.value,
                  };
                }
                return option;
              }),
            };
          });
          arrChanges = Array.from(
            new Map(arrChanges.map((item) => [item.field, item])).values()
          );

          let errorOnUpdate = false;
          for (const item of updateItems) {
            const url = `${API_PROD_URL}/cart/update-item/${item.id}`;
            const body = { options: [...item.options] };
            const [err] = await to(axios.patch(url, body, getConfig()));
            if (err) {
              errorOnUpdate = true;
              break;
            }
          }
          if (errorOnUpdate || arrChanges.length === 0) return !errorOnUpdate;

          const currentUser = useCurrentUser.getState().user;
          const by = currentUser
            ? `${currentUser.username} <${currentUser.email}>`
            : "CS";
          const log = {
            message: "Cart item options updated>>" + JSON.stringify(arrChanges),
            by,
            type: "Purchase",
            status: purchaseProvider.status,
            purchaseProviderId: purchaseProvider.id as string,
          };
          await createLog(log);
          return !errorOnUpdate;
        },
        updatePurchaseEmailByCS: async () => {
          const { newBooking, selectedBooking } = get();
          const { purchases_providers } = selectedBooking.booking_handler;
          const purchaseProviderId = purchases_providers[0].id;
          const url = `${
            get().API_PROD_URL
          }/purchase-provider/${purchaseProviderId}`;
          const body = {
            status: purchases_providers[0].status,
            purchase_mail: newBooking.purchaseEmail,
            by: "CS",
            message:
              "Update purchase email to " +
              newBooking.purchaseEmail +
              " by: " +
              localStorage.getItem("username"),
          };
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;
          console.log("response", response);
          return true;
        },
        updatePaymentId: async () => {
          const { newBooking, oldBooking, selectedBooking } = get();
          const purchaseProviderId =
            selectedBooking.booking_handler.purchases_providers[0].id;

          console.log("updatePaymentId");
          console.log("newBooking", newBooking);
          console.log("oldBooking", oldBooking);

          const paymentProvider =
            selectedBooking.booking_handler.purchases_providers[0]
              .payment_provider;

          const body = {
            payment_provider: {
              ...paymentProvider,
              payment_id: newBooking.paymentId,
            },
            by: "CS",
            message:
              "Update payment id to " +
              newBooking.paymentId +
              " by: " +
              localStorage.getItem("username"),
            status:
              selectedBooking.booking_handler.purchases_providers[0].status,
          };

          const url = `${
            get().API_PROD_URL
          }/purchase-provider/${purchaseProviderId}`;
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;
          console.log("response payment id", response);

          return true;
        },
        updateStatusBooking: async () => {
          const { newBooking, oldBooking, selectedBooking } = get();
          const body: any = {
            by: "CS",
            message:
              "Update booking status to " +
              newBooking.status +
              " by: " +
              localStorage.getItem("username"),
          };
          if (newBooking.status !== oldBooking.status)
            body["status"] = newBooking.status;

          if (Object.keys(body).length === 0) return true;
          const url = `${get().API_PROD_URL}/purchase-provider/${
            selectedBooking.booking_handler.purchases_providers[0].id
          }`;
          console.log("body status update", body);
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;
          console.log("response", response);

          return true;
        },
        updateStatusPurchaseProvider: async (status: string) => {
          const { selectedBooking } = get();
          const body = {
            status,
            by: "CS",
            message:
              "Update status to " +
              status +
              " by: " +
              localStorage.getItem("username"),
          };
          const url = `${get().API_PROD_URL}/purchase-provider/${
            selectedBooking.booking_handler.purchases_providers[0].id
          }`;
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;
          console.log("response", response);
          return true;
        },
        updateTimeRange: async () => {
          const { newBooking, selectedBooking } = get();
          const { cart } = selectedBooking;
          const { options } = cart.items[0];
          var entryTimeObject = options.find(
            (option: any) => option.name === "EntryTime"
          );
          if (entryTimeObject) {
            entryTimeObject.value = newBooking.timeRange;
          }

          // patch 'update-item/:item_id
          const url = `${get().API_PROD_URL}/cart/update-item/${
            cart.items[0].id
          }`;
          const body = {
            options: [
              {
                option_id: entryTimeObject.id,
                name: entryTimeObject.name,
                value: entryTimeObject.value,
              },
            ],
          };
          console.log("body time update", body);
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;
          console.log("response", response);

          return true;
        },
        updateVisitDate: async () => {
          const { newBooking, selectedBooking } = get();
          const { cart } = selectedBooking;
          const { options } = cart.items[0];
          var entryDateObject = options.find(
            (option: any) => option.name === "EntryDate"
          );
          if (entryDateObject) {
            entryDateObject.value = newBooking.visitDate;
          }

          // patch 'update-item/:item_id
          const url = `${get().API_PROD_URL}/cart/update-item/${
            entryDateObject.id
          }`;
          const body = {
            ...entryDateObject,
            by: "CS",
            message:
              "Update booking visit date to " +
              newBooking.visitDate +
              " by: " +
              localStorage.getItem("username"),
          };
          console.log("body visit date update", body);
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;

          return true;
        },
        updateStatusPurchaseProviderSuccess: async () => {
          const { selectedBooking } = get();

          const url = `${get().API_PROD_URL}/purchase-provider/${
            selectedBooking.booking_handler.purchases_providers[0].id
          }`;
          const body = {
            status: "Success",
            message: "Manual purchase by: " + localStorage.getItem("username"),
            by: "CS",
          };

          console.log("body status update", body);

          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;
          console.log("response", response);

          return true;
        },
        manualPurchase: async () => {
          const {
            newManualPurchase,
            updateTrackingNumber,
            updateCard,
            updatePurchaseEmail,
            updateStatusPurchaseProviderSuccess,
            selectedBooking,
          } = get();

          const paymentProvider =
            selectedBooking.booking_handler.purchases_providers[0]
              .payment_provider;

          const body = {
            tracking_number: newManualPurchase.trackingNumber,
            purchase_mail: newManualPurchase.purchaseEmail,
            status: "Success",
            payment_provider: {
              ...paymentProvider,
              credit_card: {
                cvv: newManualPurchase.cvv,
                month: newManualPurchase.month,
                year: newManualPurchase.year,
                number_card: newManualPurchase.card,
                id: "CS",
                full_name: newManualPurchase.fullName,
              },
            },
            total_paid: parseFloat(newManualPurchase.total_paid),
          };

          console.log("body manual purchase", body);

          const url = `${get().API_PROD_URL}/purchase-provider/manual-purchase/${
            selectedBooking.booking_handler.purchases_providers[0].id
          }`;
          const [err, response] = await to(
            axios.post(url, body, get().getConfig())
          );
          if (err) return false;
          console.log("response", response);

          return true;
        },
        refundBooking: async () => {
          console.log("refund Booking");
          const { refundData, selectedBooking } = get();
          const { payment } = selectedBooking;

          const url = `${get().API_PROD_URL}/payment/refund/${payment.id}`;
          const [err, response] = await to(
            axios.post(url, refundData, get().getConfig())
          );
          if (err) return false;
          console.log("response", response);

          const status = `${selectedBooking.booking_handler.purchases_providers[0].status}`;

          const body = {
            status,
            by: "CS",
            message: "Refund managed by: " + localStorage.getItem("username"),
          };

          const urlUpdate = `${get().API_PROD_URL}/purchase-provider/${
            selectedBooking.booking_handler.purchases_providers[0].id
          }`;
          const [e, res] = await to(
            axios.patch(urlUpdate, body, get().getConfig())
          );
          if (e) return false;
          console.log("res", res);

          return true;
        },
        unblockBooking: async () => {
          console.log("unblockBooking");
          // TODO: unblock booking from the API
          //const url = `${post().API_URL}/`;
          //const [err, response] = await to();
          //if (err) return false;
          return true;
        },
        sendTickets: async (actionFrom?: string) => {
          const {
            selectedBookings,
            bookingsPending,
            selectedBooking,
            getConfig,
          } = get();
          let query = "?";
          if (actionFrom === "bookingDetails") {
            query += `purchaseProviderId=${selectedBooking.booking_handler.purchases_providers[0].id}`;
          } else {
            const selectedBookingsData = bookingsPending.filter((booking) =>
              selectedBookings.includes(booking.id)
            );

            const selectedPurchaseProvidersIds: any[] = [];

            selectedBookingsData.map((booking) => {
              const { purchases_providers } = booking.booking_handler;
              purchases_providers.map((purchaseProvider: any) => {
                selectedPurchaseProvidersIds.push(purchaseProvider.id);
              });
            });
            // TODO: send tickets from the API with the selected purchase providers ids by quey params
            selectedPurchaseProvidersIds.map(
              (purchaseProviderId: any, index: any) => {
                if (index === 0) {
                  query += `purchaseProviderId=${purchaseProviderId}`;
                } else {
                  query += `&purchaseProviderId=${purchaseProviderId}`;
                }
              }
            );
          }

          const url = `${
            get().API_PROD_URL
          }/file-management/pendings/send-tickets${query}`;
          const [err, response] = await to(axios.post(url, {}, getConfig()));
          if (err) return false;
          return true;
        },
        retryTickets: async () => {
          console.log("retryTickets");
          // TODO: retry to buy tickets from the API with the bot
          // endpoind  @Post('resend-items-bot/:id')
          //const url = `${post().API_URL}/purchase-provider/resend-items-bot/:id`;
          //const [err, response] = await to();
          //if (err) return false;
          return true;
        },
        updateCustomer: async (custumerId: string) => {
          const { newCustomer, oldCustomer } = get();
          const body: any = {};
          if (newCustomer.firstName !== oldCustomer.firstName) {
            body["first_name"] = newCustomer.firstName;
          }
          if (newCustomer.lastName !== oldCustomer.lastName) {
            body["last_name"] = newCustomer.lastName;
          }
          if (newCustomer.email !== oldCustomer.email) {
            body["email"] = newCustomer.email;
          }

          if (Object.keys(body).length === 0) return true;
          const url = `${get().API_PROD_URL}/customers/${custumerId}`;
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;

          return true;
        },
        updateTrackingNumber: async (newTrackingNumber: string) => {
          const { selectedBooking } = get();
          const purchaseProviderId =
            selectedBooking.booking_handler.purchases_providers[0].id;
          const url = `${
            get().API_PROD_URL
          }/purchase-provider/${purchaseProviderId}`;
          const body = {
            status:
              selectedBooking.booking_handler.purchases_providers[0].status,
            tracking_number: newTrackingNumber,
            by: "CS",
            message:
              "Update tracking number to " +
              newTrackingNumber +
              " by: " +
              localStorage.getItem("username"),
          };
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;
          return true;
        },
        updateCard: async () => {
          const { newManualPurchase, selectedBooking } = get();

          const paymentProvider =
            selectedBooking.booking_handler.purchases_providers[0]
              .payment_provider;

          const body = {
            status:
              selectedBooking.booking_handler.purchases_providers[0].status,
            by: "CS",
            payment_provider: {
              ...paymentProvider,
              credit_card: {
                ...paymentProvider.credit_card,
                number_card: newManualPurchase.card
                  ? newManualPurchase.card
                  : paymentProvider.credit_card.number_card,
                cvv: newManualPurchase.cvv
                  ? newManualPurchase.cvv
                  : paymentProvider.credit_card.cvv,
                month: newManualPurchase.month
                  ? newManualPurchase.month
                  : paymentProvider.credit_card.month,
                year: newManualPurchase.year
                  ? newManualPurchase.year
                  : paymentProvider.credit_card.year,
                full_name: newManualPurchase.fullName
                  ? newManualPurchase.fullName
                  : paymentProvider.credit_card.full_name,
              },
            },
          };

          console.log("body card update", body);

          const url = `${get().API_PROD_URL}/purchase-provider/${
            selectedBooking.booking_handler.purchases_providers[0].id
          }`;
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;
          console.log("response update card", response);

          return true;
        },
        resendToBot: async () => {
          const { selectedBookings, bookingsPending } = get();
          const selectedBookingsData = bookingsPending.filter((booking) =>
            selectedBookings.includes(booking.id)
          );

          const selectedPurchaseProvidersIds: any[] = [];

          selectedBookingsData.map((booking) => {
            const { purchases_providers } = booking.booking_handler;
            purchases_providers.map((purchaseProvider: any) => {
              if (purchaseProvider.status === "Error") {
                selectedPurchaseProvidersIds.push(purchaseProvider.id);
              }
            });
          });

          selectedPurchaseProvidersIds.map(async (purchaseProviderId: any) => {
            const url = `${
              get().API_PROD_URL
            }/purchase-provider/resend-items-bot/${purchaseProviderId}`;
            const [err, response] = await to(
              axios.post(url, {}, get().getConfig())
            );
            if (err) return false;
          });

          if (selectedPurchaseProvidersIds.length === 0) {
            return false;
          }

          return true;
        },
        fileManagement: {},
        getFileManagement: async (purchaseProviderId: string) => {
          const url = `${
            get().API_PROD_URL
          }/file-management/by-purchase-provider/${purchaseProviderId}`;
          const [err, response] = await to(axios.get(url, get().getConfig()));
          if (err) return false;
          //console.log("response file management", response.data);
          return response.data;
        },
        downloadTickets: async (bookingIds?: string[]) => {
          const {
            getFileManagement,
            bookingsLocalStorage,
            bookingsPendingsLocalStorage,
            setSnackBar,
          } = get();
          bookingIds?.map(async (bookingId) => {
            let booking = bookingsLocalStorage.find(
              (booking) => booking.id === bookingId
            );
            if (!booking)
              booking = bookingsPendingsLocalStorage.find(
                (booking) => booking.id === bookingId
              );
            console.log("booking", booking);
            if (booking) {
              const { purchases_providers } = booking.booking_handler;
              const purchaseProviderId = purchases_providers[0].id;

              const fileManagement =
                await getFileManagement(purchaseProviderId);
              //console.log("fileManagement", fileManagement);
              const downloadTicketsConfig = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(
                    "ticketdoorToken"
                  )}`,
                  responseType: "blob",
                },
              };
              const url = `${
                get().API_PROD_URL
              }/file-management/retrieve/tickets?purchaseProviderId=${purchaseProviderId}`;
              const response = await fetch(url, downloadTicketsConfig);
              console.log("response", response);
              if (response.status !== 200) {
                setSnackBar({
                  open: true,
                  message: "Error al descargar los tickets",
                  severity: "error",
                });
                setTimeout(() => {
                  setSnackBar({
                    open: false,
                    message: "Error al descargar los tickets",
                    severity: "error",
                  });
                }, 2000);
                return false;
              }
              const data = await response.blob();

              const href = window.URL.createObjectURL(data);
              const link = document.createElement("a");
              link.href = href;
              link.setAttribute(
                "download",
                fileManagement.tickets_file_count === 1
                  ? "ticket.pdf"
                  : "tickets.zip"
              );

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              URL.revokeObjectURL(url);
            }
          });
          return true;
        },
        downloadBill: async (bookingIds?: string[]) => {
          const {
            getFileManagement,
            bookingsLocalStorage,
            bookingsPendingsLocalStorage,
            setSnackBar,
          } = get();

          bookingIds?.map(async (bookingId) => {
            let booking = bookingsLocalStorage.find(
              (booking) => booking.id === bookingId
            );
            if (!booking)
              booking = bookingsPendingsLocalStorage.find(
                (booking) => booking.id === bookingId
              );
            if (booking) {
              const { purchases_providers } = booking.booking_handler;
              const purchaseProviderId = purchases_providers[0].id;

              const fileManagement =
                await getFileManagement(purchaseProviderId);

              const downloadBillConfig = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(
                    "ticketdoorToken"
                  )}`,
                  responseType: "blob",
                },
              };
              const url = `${
                get().API_PROD_URL
              }/file-management/retrieve/bill?purchaseProviderId=${purchaseProviderId}`;
              const response = await fetch(url, downloadBillConfig);
              if (response.status !== 200) {
                setSnackBar({
                  open: true,
                  message: "Error al descargar la factura",
                  severity: "error",
                });
                setTimeout(() => {
                  setSnackBar({
                    open: false,
                    message: "Error al descargar la factura",
                    severity: "error",
                  });
                }, 2000);
                return false;
              }
              const data = await response.blob();

              const href = window.URL.createObjectURL(data);
              const link = document.createElement("a");
              link.href = href;
              link.setAttribute("download", "bill.pdf");

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              URL.revokeObjectURL(url);
            }
          });

          return true;
        },
        getBookingHandler: async (bookingHandlerId: string) => {
          const url = `${get().API_PROD_URL}/booking-handler/${bookingHandlerId}`;
          const [err, response] = await to(axios.get(url, get().getConfig()));
          if (err) return false;
          return response.data;
        },
        purchaseProvider: {},
        setPurchaseProvider: (purchaseProvider: any) =>
          set({ purchaseProvider }),
        getPurchaseProvider: async () => {
          console.log("INit purchase provider");
          const { selectedBooking } = get();
          console.log("selectedBooking", selectedBooking);
          const { purchases_providers } = selectedBooking.booking_handler ?? [];
          if (!purchases_providers) {
            set({ purchaseProvider: {} });
            return false;
          }
          const url = `${get().API_PROD_URL}/purchase-provider/${
            purchases_providers[0].id
          }`;
          const [err, response] = await to(axios.get(url, get().getConfig()));
          if (err) return false;
          set({ purchaseProvider: response.data });
          return true;
        },
        getPurchasesProvidersByBookingId: async (bookingId: string) => {
          const url = `${
            get().API_PROD_URL
          }/purchase-provider/by-booking/${bookingId}`;
          const [err, response] = await to(axios.get(url, get().getConfig()));
          if (err) return false;
          return response.data;
        },
        updatePurchaseEmail: async (email) => {
          const { selectedBooking } = get();
          const url = `${get().API_PROD_URL}/purchase-provider/${
            selectedBooking.booking_handler.purchases_providers[0].id
          }`;
          const { cart } = selectedBooking;
          const activityName: string = cart.items[0].activity.name;
          // @ts-ignore
          const singleVar = Vars[activityName];
          const body = {
            by: "CS",
            status:
              selectedBooking.booking_handler.purchases_providers[0].status,
            purchase_mail: singleVar ? singleVar.emailPurchase : email,
          };
          console.log("body email update", body);
          const [err, response] = await to(
            axios.patch(url, body, get().getConfig())
          );
          if (err) return false;
          console.log("response update purchase email", response);
          return true;
        },
        downloadCSV: async () => {
          console.log("downloadCSV");
          const { selectedVenues, dateRangeFilter, setSnackBar } = get();

          // si no tiene ningun filtro de venue, return false
          if (selectedVenues.length === 0) return false;

          let query_date = "";

          if (dateRangeFilter.startDate) {
            query_date += `&start_date=${moment(dateRangeFilter.startDate)
              .startOf("day")
              .toISOString()}`;
          }

          if (dateRangeFilter.endDate) {
            query_date += `&end_date=${moment(dateRangeFilter.endDate)
              .endOf("day")
              .toISOString()}`;
          }

          let query_venue = "";

          selectedVenues.map((venue) => {
            query_venue += `&venue_id=${venue}`;
          });

          const url = `${
            get().API_PROD_URL
          }/bookings/download/csv?status_booking=Processing${query_date}${query_venue}`;
          console.log("url download csv", url);

          const downloadCsvConfig = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("ticketdoorToken")}`,
              responseType: "blob",
            },
          };

          const response = await fetch(url, downloadCsvConfig);
          if (response.status !== 200) {
            setSnackBar({
              open: true,
              message: "Error al descargar los tickets",
              severity: "error",
            });
            setTimeout(() => {
              setSnackBar({
                open: false,
                message: "Error al descargar los tickets",
                severity: "error",
              });
            }, 2000);
            return false;
          }

          console.log("response download csv", response);
          const data = await response.blob();

          // download csv
          const href = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "bookings.csv");

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          URL.revokeObjectURL(url);

          return true;
        },
        searchBooking: async (actionFrom: string) => {
          var { inputSearch } = get();
          if (
            inputSearch.includes("transaction") ||
            inputSearch.includes("TRANSACTION")
          ) {
            inputSearch = inputSearch.toLowerCase();
          }
          const url = `${
            get().API_PROD_URL
          }/bookings/find/criteria?value=${inputSearch}`;
          //console.log("url search", url);
          const [err, response] = await to(axios.get(url, get().getConfig()));
          if (err) return false;
          //console.log("response", response.data);

          if (actionFrom === "booking") {
            console.log("bookings", response.data);
            // if booking_handler is "Error" or "Processing" don't show the booking
            const filteredBookings = response.data.filter(
              (booking: any) => booking.booking_handler?.status === "Success"
            );
            set({
              bookings: filteredBookings,
              bookingsLocalStorage: filteredBookings,
            });
          } else {
            console.log("pendings", response.data);
            const filteredPendings = response.data.filter(
              (booking: any) =>
                booking.booking_handler?.status === "Processing" ||
                booking.booking_handler?.status === "Error"
            );
            set({
              bookingsPending: filteredPendings,
              bookingsPendingsLocalStorage: filteredPendings,
            });
          }

          return true;
        },
        uploadFile: async () => {
          console.log("uploadFile");
          const {
            convertToBase64,
            uploadFiles,
            fileTypeToUpload,
            idBookingToUploadFile,
            selectedBooking,
            updateStatusPurchaseProvider,
            setSnackBar,
          } = get();
          const { purchases_providers } = selectedBooking.booking_handler;

          if (
            purchases_providers[0].status === "Processing" ||
            purchases_providers[0].status === "Error"
          ) {
            const doneUpdateStatusBooking =
              await updateStatusPurchaseProvider("Success");
            if (!doneUpdateStatusBooking) return false;
            // wait 5 seconds to follow with the upload file
            await new Promise((r) => setTimeout(r, 5000));
          }

          console.log("status success");
          const result = await uploadFiles.map(
            async (file: any, index: number) => {
              const base64String = await convertToBase64(file);
              const base64 = base64String.split(",")[1];
              console.log("base64", base64);
              console.log("purchaseProviderId", purchases_providers[0].id);
              console.log("base64 lenght", base64.length);
              const url = `${get().API_PROD_URL}/file-management/upload-file`;
              const body = {
                purchaseProviderId: purchases_providers[0].id,
                base64: base64,
                fileType: fileTypeToUpload,
                index: index + 1,
              };
              const [err, response] = await to(
                axios.patch(url, body, get().getConfig())
              );
              console.log("err", err);
              if (err) {
                setSnackBar({
                  open: true,
                  message: "Error uploading file",
                  severity: "error",
                });
                setTimeout(() => {
                  setSnackBar({
                    open: false,
                    message: "Error uploading file",
                    severity: "error",
                  });
                }, 2000);
                return false;
              } else {
                setSnackBar({
                  open: true,
                  message: "Uploaded file successfully",
                  severity: "success",
                });
                setTimeout(() => {
                  setSnackBar({
                    open: false,
                    message: "Uploaded file successfully",
                    severity: "success",
                  });
                }, 2000);
              }
              console.log("response", response);
              return true;
            }
          );
          return result;
        },
        convertToBase64: async (file: any) => {
          return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
              resolve(fileReader.result as string);
            };

            fileReader.onerror = (error) => {
              reject(error);
            };
          });
        },
        sendEmailIndisponibility: async (purchase_provider_ids: string[]) => {
          const url = `${get().API_PROD_URL}/purchase-provider/send-no-availability`;
          const [err, response] = await to(
            axios.post(url, { purchase_provider_ids }, get().getConfig())
          );
          if (err)
            return {
              isDone: false,
              msg: "Error api sending email",
            };
          console.log("response", response);
          return {
            isDone: response.data.isDone,
            msg: response.data.msg,
          };
        },
        retryFiles: async (purchase_provider_id: string) => {
          const url = `${get().API_PROD_URL}/file-management/retry-files/${purchase_provider_id}`;
          const [err] = await to(axios.get(url, get().getConfig()));
          if (err) return false;
          return true;
        },
        // OPEN MODALS
        openEditDialog: false,
        setOpenEditDialog: (openEditDialog: boolean) => set({ openEditDialog }),
        openManualPurchaseDialog: false,
        setOpenManualPurchaseDialog: (openManualPurchaseDialog: boolean) =>
          set({ openManualPurchaseDialog }),
        openRefundDialog: false,
        setOpenRefundDialog: (openRefundDialog: boolean) =>
          set({ openRefundDialog }),
        openUnblockDialog: false,
        setOpenUnblockDialog: (openUnblockDialog: boolean) =>
          set({ openUnblockDialog }),
        openEditCustomerDialog: false,
        setOpenEditCustomerDialog: (openEditCustomerDialog: boolean) =>
          set({ openEditCustomerDialog }),
        openUploadFileDialog: false,
        setOpenUploadFileDialog: (openUploadFileDialog: boolean) =>
          set({ openUploadFileDialog }),
        openRevisedDialog: false,
        setOpenRevisedDialog: (openRevisedDialog: boolean) =>
          set({ openRevisedDialog }),
        openRemoveFileDialog: false,
        setOpenRemoveFileDialog: (openRemoveFileDialog: boolean) =>
          set({ openRemoveFileDialog }),
        // Comments
        comment: "",
        setComment: (comment: string) => set({ comment }),
        // id booking to contact
        idBookingToContact: "",
        setIdBookingToContact: (idBookingToContact: string) =>
          set({ idBookingToContact }),
        // STATUS REPORT
        statusReport: [],
        loadStatusReport: async (startDate, endDate) => {
          console.log("INIT LOAD STATUS REPORT");
          const API_URL = get().API_PROD_URL;
          //console.log("startDate", startDate);
          const strStartDate = new Date(startDate).toISOString();
          const strEndDate = new Date(endDate).toISOString();
          const url = `${API_URL}/bookings/find/count?start_date=${strStartDate}&end_date=${strEndDate}&status_booking=`;
          const venues = get().selectedVenues;
          var urlVenues = "";
          venues.map((venue) => {
            urlVenues += `&venue_id=${venue}`;
          });

          const totalOrders = await axios.get(
            `${url}Processing&${urlVenues}`,
            get().getConfig()
          );
          const revisedsOrders = await axios.get(
            `${url}Processing&status_booking_handler=Success&status_payment=Success&status_payment=Authorized${urlVenues}`,
            get().getConfig()
          );
          const processingOrders = await axios.get(
            `${url}Processing&status_booking_handler=Processing${urlVenues}`,
            get().getConfig()
          );
          const errorOrders = await axios.get(
            `${url}Processing&status_booking_handler=Error&status_payment=Created&status_payment=Authorized&status_payment=Success${urlVenues}`,
            get().getConfig()
          );
          const refundOrders = await axios.get(
            `${url}Processing&status_payment=Refund${urlVenues}`,
            get().getConfig()
          );
          const partialRefundOrders = await axios.get(
            `${url}Processing&status_payment=RefundPartial${urlVenues}`,
            get().getConfig()
          );
          const chargebackOrders = await axios.get(
            `${url}Processing&status_payment=Chargeback${urlVenues}`,
            get().getConfig()
          );
          //console.log("totalOrders", totalOrders.data);
          //console.log("revisedsOrders", revisedsOrders.data);
          const statusReport = [
            {
              label: "Total Orders",
              value: totalOrders?.data ?? 0,
              color: "black",
            },
            {
              label: "Success",
              value: revisedsOrders?.data ?? 0,
              color: "lightgreen",
            },
            {
              label: "Processing",
              value: processingOrders?.data ?? 0,
              color: "gray",
            },
            {
              label: "Error",
              value: errorOrders?.data ?? 0,
              color: "red",
            },
            {
              label: "Refund",
              value: refundOrders?.data ?? 0,
              color: "yellow",
            },
            {
              label: "Partial Refund",
              value: partialRefundOrders?.data ?? 0,
              color: "orange",
            },
            {
              label: "Chargeback",
              value: chargebackOrders?.data ?? 0,
              color: "darkred",
            },
          ];
          set(() => ({ statusReport: statusReport }));
        },
      };
    },
    {
      name: "booking",
      getStorage: () => localStorage,
      partialize: (state) => ({
        dateRangeFilter: state.dateRangeFilter,
        pagination: state.pagination,
        venues: state.venues,
        inputSearch: state.inputSearch,
        filterStatus: state.filterStatus,
        selectedVenues: state.selectedVenues,
        selectedBookings: state.selectedBookings,
        selectedBooking: state.selectedBooking,
        //bookingsLocalStorage: state.bookingsLocalStorage,
        //bookingsPendingsLocalStorage: state.bookingsPendingsLocalStorage,
        //filterCreatedAt: state.filterCreatedAt,
      }),
    }
  )
);

export default useBookingStore;
