import { Button, CircularProgress } from "@mui/material";
import { Fragment } from "react";

interface Props {
  id: string;
  clickHandler: (id: string) => void;
  loadingButtons: { [x: string]: boolean };
  children: React.ReactNode;
}

const ButtonWithLoader = (props: Props) => {
  return (
    <Button
      variant="outlined"
      size="small"
      id={props.id}
      onClick={(e) => props.clickHandler((e.target as HTMLButtonElement).id)}
      disabled={props.loadingButtons[props.id]}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {props.loadingButtons[props.id] ? (
        <Fragment>
          {props.children}
          <CircularProgress size={20} sx={{ color: "inherit" }} />
        </Fragment>
      ) : (
        props.children
      )}
    </Button>
  );
};

export default ButtonWithLoader;
