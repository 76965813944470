import { FC, PropsWithChildren, ReactNode } from "react";
import { hasPermission } from "../utils/permissions";

interface PermissionWrapperProps {
  userPermissions: any;
  requiredPermission: string;
  children: ReactNode;
}

const PermissionWrapper = ({
  userPermissions,
  requiredPermission,
  children,
}: PropsWithChildren<PermissionWrapperProps>) => {
  return hasPermission(userPermissions, requiredPermission) ? (
    <>{children}</>
  ) : null;
};

export default PermissionWrapper;
