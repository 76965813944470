import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { LogChangeRow } from "../interfaces/LogChangeRow.interface";

const EditDetails = ({ details }: { details: string }) => {
  const parsedDetails: LogChangeRow[] = JSON.parse(details);

  return (
    <Table size="small" sx={{ maxWidth: "100%" }}>
      <TableHead>
        <TableRow>
          <TableCell>Field</TableCell>
          <TableCell>Old</TableCell>
          <TableCell>New</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {parsedDetails.map((changeRow, key) => (
          <TableRow key={`${key}-details`}>
            <TableCell>{changeRow.field}</TableCell>
            <TableCell>{changeRow.old}</TableCell>
            <TableCell>{changeRow.new}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default EditDetails;
