import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useBookingStore from "../../store/bookings";
import LogRow from "./components/LogRow";
import { sortLogs } from "./utils/logsUtils";
import { Logs } from "../../_config/customerService.interface";

const LogsTable = () => {
  const bookingStore = useBookingStore();

  return (
    <TableContainer title="Logs" component={Paper}>
      <Table size="small" aria-label="a logs table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {((bookingStore.purchaseProvider?.logs as Logs[]) ?? [])
            .sort(sortLogs)
            .map((log) => (
              <LogRow key={`${log.id}-row`} log={log} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LogsTable;
