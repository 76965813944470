import { Box, Chip, TableRow, TableCell } from "@mui/material";
import EditDetails from "./EditDetails";
import {
  bgColors,
  chipColors,
  parseMessage,
  formatDate,
} from "../utils/logsUtils";
import { Logs } from "../../../_config/customerService.interface";

const LogRow = ({ log }: { log: Logs }) => {
  const bgColor = bgColors[log.type as keyof typeof bgColors] || "white";
  const [message, details] = parseMessage(log.message || "");

  return (
    <>
      <TableRow key={`${log.id}-row`} sx={{ backgroundColor: bgColor }}>
        <TableCell>{formatDate(log.created_at)}</TableCell>
        <TableCell>
          <Chip
            label={log.status}
            color={
              chipColors[log.status as keyof typeof chipColors] || "default"
            }
            variant="outlined"
            size="small"
          />
        </TableCell>
        <TableCell>{message}</TableCell>
        <TableCell>{log.by}</TableCell>
      </TableRow>
      {details && (
        <TableRow>
          <TableCell colSpan={4}>
            <Box sx={{ m: 0, ml: 1 }}>
              <EditDetails details={details} />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default LogRow;
