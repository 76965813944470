import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import CryptoJS from "crypto-js";
import { Config } from "../config";

const encryptKey = Config.REACT_APP_ENCRYPT_KEY;

const encrypt = (data: string, secretKey: string) => {
  const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
  return ciphertext;
};

const decrypt = (ciphertext: string, secretKey: string) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

interface UserPermissionsState {
  permissions: UserPermissions | null;
  setPermissions: (permissions: UserPermissions) => void;
}

export interface UserPermissions {
  can_see_view_overview: boolean;
  can_see_view_bookings: boolean;
  can_see_view_pendings: boolean;
  can_see_view_to_do: boolean;
  can_see_view_availability: boolean;
  can_see_view_finance: boolean;
  can_see_view_analytics: boolean;
  can_see_view_users_management: false;
  can_see_view_cs_performance_metrics: false;
  can_do_in_bookings_add_custom_commentaries: boolean;
  can_do_in_bookings_attach_bills: boolean;
  can_do_in_bookings_attach_tickets: boolean;
  can_do_in_bookings_download_bills: boolean;
  can_do_in_bookings_download_invoice: boolean;
  can_do_in_bookings_download_tickets: boolean;
  can_do_in_bookings_edit_booking: boolean;
  can_do_in_bookings_export_bookings_report: boolean;
  can_do_in_bookings_generate_invoice: boolean;
  can_do_in_bookings_make_refund: boolean;
  can_do_in_bookings_manual_purchase: boolean;
  can_do_in_bookings_mark_contacted_checkbox: boolean;
  can_do_in_bookings_send_confirmation_mail: boolean;
  can_do_in_bookings_send_refund_reschedule_mail: boolean;
  can_do_in_bookings_send_resolution_mail: boolean;
  can_do_in_bookings_unattach_bills: boolean;
  can_do_in_bookings_unattach_tickets: boolean;
  can_do_in_bookings_use_button_resend_bot: boolean;
  venue_ids?: string[];
}

const useUserPermissions = create<UserPermissionsState>()(
  persist(
    (set, get) => {
      return {
        permissions: null,
        setPermissions: (permissions: UserPermissions) => {
          console.log("permissions", permissions);
          set({ permissions });
          console.log("permissions root", get().permissions);
        },
      };
    },
    {
      name: "user-permissions",
      storage: createJSONStorage(() => ({
        getItem: (name) => {
          const encrypted = localStorage.getItem(name);
          if (encrypted) {
            const decrypted = decrypt(encrypted, encryptKey);
            return decrypted;
          }
          return null;
        },
        setItem: (name, value) => {
          const encrypted = encrypt(value, encryptKey);
          localStorage.setItem(name, encrypted);
        },
        removeItem: (name) => localStorage.removeItem(name),
      })),
      partialize: (state) => ({ permissions: state.permissions }),
      version: 1,
    }
  )
);

export default useUserPermissions;
