import moment from "moment";

export const bgColors = {
  Contacted: "lightgreen",
  Comment: "lightblue",
};

export const chipColors: { [x: string]: "warning" | "error" | "success" } = {
  Processing: "warning",
  Error: "error",
  Success: "success",
};

export const formatDate = (date: string) =>
  moment(date).format("DD/MM/YYYY HH:mm");

export const sortLogs = (prev: any, next: any) =>
  moment(prev.created_at).isBefore(next.created_at) ? -1 : 1;

export const parseMessage = (message: string) =>
  message ? message.split(">>") : [""];