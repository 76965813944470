import React, { createContext, useContext, ReactNode } from "react";
import { Snackbar, Alert } from "@mui/material";
import useSnackbar from "../hooks/useSnackbar";

// Define the type for the context value
interface SnackbarContextType {
  showSnackbar: (
    message: string,
    severity?: "success" | "info" | "warning" | "error",
    duration?: number
  ) => void;
}

// Provide a default value for the context
const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children,
}) => {
  const { snackbar, showSnackbar, closeSnackbar } = useSnackbar();

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

// Custom hook to use the Snackbar context
export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error(
      "useSnackbarContext must be used within a SnackbarProvider"
    );
  }
  return context;
};
