import { create } from "zustand";
import { Config } from "../config";
import { User } from "../pages/settings/users/types/users.interface";
import axios from "axios";
import CryptoJS from "crypto-js";
import { createJSONStorage, persist } from "zustand/middleware";

const encryptKey = Config.REACT_APP_ENCRYPT_KEY;

const encrypt = (data: string, secretKey: string) => {
  const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
  return ciphertext;
};

const decrypt = (ciphertext: string, secretKey: string) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

interface UserState {
  user: User | null;
  searchUserByEmail: (email: string) => Promise<User>;
}

const useCurrentUser = create<UserState>()(
  persist(
    (set) => {
      return {
        user: null,
        async searchUserByEmail(email) {
          const url = `${Config.REACT_APP_API_TICKETDOOR}/users/by-email/${email}`;
          const response = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(`ticketdoorToken`)}`,
            },
          });
          if (response.status === 200) {
            set({ user: response.data });
            return response.data;
          }
        },
      };
    },
    {
      name: "current-user-storage",
      storage: createJSONStorage(() => ({
        getItem: (name) => {
          const encrypted = localStorage.getItem(name);
          if (encrypted) {
            const decrypted = decrypt(encrypted, encryptKey);
            return decrypted;
          }
          return null;
        },
        setItem: (name, value) => {
          const encrypted = encrypt(value, encryptKey);
          localStorage.setItem(name, encrypted);
        },
        removeItem: (name) => localStorage.removeItem(name),
      })),
      partialize: (state) => ({ user: state.user }),
      version: 1,
    }
  )
);

export default useCurrentUser;
