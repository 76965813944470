import { Alert, Snackbar } from "@mui/material";
import { useToast } from "../pages/customers/hooks/useToast";

export function Toast() {
  const { toast } = useToast();

  return (
    <Snackbar open={toast.open} autoHideDuration={2000}>
      <Alert severity={toast.severity}>{toast.message}</Alert>
    </Snackbar>
  );
}
