import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import useBookingStore from "../../store/bookings";
import { useEffect, useState } from "react";
import Vars from "../../_config/vars";

const EditDialog = () => {
  const [hours, setHours] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    openEditDialog,
    setOpenEditDialog,
    updateBooking,
    newBooking,
    setNewBooking,
    selectedBookingHandler,
    oldBooking,
    setOldBooking,
    updateTrackingNumber,
    setSnackBar,
    selectedBooking,
    setBookingsPending,
    getPurchaseProvider,
    setSelectedBooking,
    setBookings,
    searchBooking,
  } = useBookingStore((state) => ({
    openEditDialog: state.openEditDialog,
    setOpenEditDialog: state.setOpenEditDialog,
    updateBooking: state.updateBooking,
    newBooking: state.newBooking,
    setNewBooking: state.setNewBooking,
    selectedBookingHandler: state.selectedBookingHandler,
    oldBooking: state.oldBooking,
    setOldBooking: state.setOldBooking,
    updateTrackingNumber: state.updateTrackingNumber,
    selectedBooking: state.selectedBooking,
    setSnackBar: state.setSnackBar,
    setBookingsPending: state.setBookingsPending,
    getPurchaseProvider: state.getPurchaseProvider,
    setSelectedBooking: state.setSelectedBooking,
    setBookings: state.setBookings,
    searchBooking: state.searchBooking,
  }));

  const { booking_handler, cart } = selectedBooking;
  const { options } = cart.items[0];
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("ticketdoorToken")}`,
    },
  };

  // get options with entryDate
  let visitDate = "";
  let timeRange = "";
  var entryDateObject = options.find(
    (option: any) => option.name === "EntryDate"
  );
  if (entryDateObject) {
    visitDate = entryDateObject.value;
  }
  var entryTimeObject = options.find(
    (option: any) => option.name === "EntryTime"
  );
  if (entryTimeObject) {
    timeRange = entryTimeObject.value;
  }

  useEffect(() => {
    setNewBooking({
      trackingNumber: booking_handler?.purchases_providers[0].tracking_number,
      //timeRange: booking_handler.purchases_providers[0].entry_time,
      paymentId:
        booking_handler?.purchases_providers[0].payment_provider.payment_id,
      purchaseEmail: booking_handler?.purchases_providers[0].purchase_mail,
      timeRange,
      status: booking_handler?.purchases_providers[0].status,
      visitDate,
    });

    setOldBooking({
      trackingNumber: booking_handler?.purchases_providers[0].tracking_number,
      //timeRange: booking_handler.purchases_providers[0].entry_time,
      paymentId:
        booking_handler?.purchases_providers[0].payment_provider.payment_id,
      purchaseEmail: booking_handler?.purchases_providers[0].purchase_mail,
      timeRange,
      status: booking_handler?.purchases_providers[0].status,
      visitDate,
    });

    getDispoinvilityHours(visitDate);
    // const activityName = cart.items[0].activity.name;
    // // @ts-ignore
    // if (activityName === "ATH - ACRO - ET") setHours(Vars[activityName].hours);
    // // @ts-ignore
    // if (activityName === "PAR - VEPA - ET") setHours(Vars[activityName].hours);
    // // @ts-ignore
    // if (activityName === "ROM - COLO - ET") setHours(Vars[activityName].hours);
    // // @ts-ignore
    // if (activityName === "BCN - SAFA - ET" || activityName === "BCN - SAFA - GT" || activityName === "BCN - SAFA - ST") setHours(Vars["BCN - SAFA - ET"].hours);
    // // @ts-ignore
    // if (activityName === "BCN - PAGU - ET" || activityName === "BCN - PAGU - GT") setHours(Vars["BCN - PAGU - ET"].hours);
  }, []);

  const getDispoinvilityHours = async (visitDate: string) => {
    var activityId = cart.items[0].activity.id;
    if (cart.items[0].activity.name === "ATH - ACRO - ST")
      activityId = "f8920518-deaf-4df9-a73f-213e0652488e";

    const url =
      "https://api.roodtekcit.com/availability/ranges/dates?start=" +
      visitDate +
      "&end=" +
      visitDate +
      "&activity_id=" +
      activityId;
    console.log("url", url);
    const response = await fetch(url, config);
    const data = await response.json();
    console.log("data", data);

    if (selectedBooking.cart.items[0].activity.name === "BCN - SAFA - ST") {
      const towerName = selectedBooking.cart.items[0].options.find(
        (option: any) => option.name === "Tower"
      ).value;
      const tower = data.find((tower: any) => tower.option.value === towerName);
      setHours(tower.timeslots);
      return;
    }

    if (
      selectedBooking.cart.items[0].activity.name === "BCN - SAFA - GT" ||
      selectedBooking.cart.items[0].activity.name === "BCN - PAGU - GT"
    ) {
      const languageTour = selectedBooking.cart.items[0].options.find(
        (option: any) => option.name === "Language"
      ).value;
      console.log("languageTour", languageTour);
      const language = data.find(
        (language: any) => language.option?.value === languageTour
      );
      console.log("language", language);
      setHours(language.timeslots);
      return;
    }

    setHours(data[0].timeslots);
  };

  const handleClose = () => {
    setOpenEditDialog(false);
  };

  const handleEdit = (value: any, key: any) => {
    console.log("value", value);
    console.log("key", key);

    if (key === "visitDate") {
      getDispoinvilityHours(value);
    }

    setNewBooking({
      ...newBooking,
      [key]: value,
    });
  };

  const handleUpdate = async () => {
    let doneTracking = true;
    setLoading(true);

    const doneUpdateBookings = await updateBooking();

    if (doneTracking && doneUpdateBookings) {
      // handleClose();
      setLoading(false);
      setSnackBar({
        open: true,
        message: "Booking updated successfully",
        type: "success",
      });

      setTimeout(() => {
        handleSelectedBooking();
        handleClose();
        setBookingsPending();
        getPurchaseProvider();
        setSnackBar({
          open: false,
          message: "Booking updated successfully",
          type: "success",
        });
      }, 2000);
    } else {
      setLoading(false);
      setSnackBar({
        open: true,
        message: "Error updating booking",
        severity: "error",
      });

      setTimeout(() => {
        handleClose();
        setBookingsPending();
        getPurchaseProvider();
        setSnackBar({
          open: false,
          message: "Error updating booking",
          severity: "error",
        });
      }, 2000);
    }
  };

  const handleSelectedBooking = async () => {
    const url = window.location.pathname;
    const id = url.substring(url.lastIndexOf("/") + 1);
    console.log("id edit dialog", id);
    await setSelectedBooking(id);
    await getPurchaseProvider();
    //console.log("selectedBooking", selectedBooking);
  };

  const open = openEditDialog;

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Edit booking</DialogTitle>
      <DialogContent>
        <TextField
          key="trackingNumber"
          name="trackingNumber"
          size="small"
          variant="standard"
          fullWidth
          label="New Tracking Number"
          value={newBooking.trackingNumber || ""}
          onChange={(e) => handleEdit(e.target.value, "trackingNumber")}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          key="purchaseEmail"
          name="purchaseEmail"
          size="small"
          variant="standard"
          fullWidth
          label="Purchase Email"
          value={newBooking.purchaseEmail || ""}
          onChange={(e) => handleEdit(e.target.value, "purchaseEmail")}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          key="paymentId"
          name="paymentId"
          size="small"
          variant="standard"
          fullWidth
          label="Payment Id"
          value={newBooking.paymentId || ""}
          onChange={(e) => handleEdit(e.target.value, "paymentId")}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          key="status"
          name="status"
          size="small"
          select
          variant="standard"
          fullWidth
          label="New Status"
          value={newBooking.status || ""}
          onChange={(e) => handleEdit(e.target.value, "status")}
          sx={{ marginBottom: 2 }}
        >
          {Vars.status.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          key="visitDate"
          name="visitDate"
          type="date"
          size="small"
          variant="standard"
          fullWidth
          label="New Visit Date"
          value={newBooking.visitDate || ""}
          onChange={(e) => handleEdit(e.target.value, "visitDate")}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          key="timeRange"
          name="timeRange"
          size="small"
          select
          variant="standard"
          fullWidth
          label="New Time Range"
          value={newBooking.timeRange || ""}
          onChange={(e) => handleEdit(e.target.value, "timeRange")}
        >
          {hours.map((option) => {
            return (
              <MenuItem key={option.time} value={option.time}>
                {option.time}
              </MenuItem>
            );
          })}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button autoFocus onClick={handleUpdate} disabled={loading}>
          Update
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
