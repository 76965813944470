import { useState, useCallback } from "react";

// Define the types for snackbar state
interface SnackbarState {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  duration: number;
}

// Custom hook for managing snackbar
const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: "",
    severity: "info",
    duration: 3000,
  });

  // Function to open the snackbar with a message and severity
  const showSnackbar = useCallback(
    (
      message: string,
      severity: "success" | "error" | "warning" | "info" = "info",
      duration: number = 3000
    ) => {
      setSnackbar({
        open: true,
        message,
        severity,
        duration,
      });
    },
    []
  );

  // Function to close the snackbar
  const closeSnackbar = useCallback(() => {
    setSnackbar((prev) => ({
      ...prev,
      open: false,
    }));
  }, []);

  return {
    snackbar,
    showSnackbar,
    closeSnackbar,
  };
};

export default useSnackbar;
